import React from "react";
import { Box } from "@mui/material";
import Logo from "../../../assets/icons/logo1.png";
import Stack from "@mui/material/Stack";
import { HearderContainer } from "./index.style";

const Header = () => {
  return (
    <HearderContainer>
      <Stack
        direction="row"
        sx={{
          height: "53px",
          backgroundColor: "background.paper",
          padding: "13px 24px 14px 24px",
        }}
      >
        <Box>
          <img src={Logo} alt="HLF Logo" />
        </Box>
      </Stack>
    </HearderContainer>
  );
};

export default Header;
