import { createTheme } from "@mui/material";
import { IState } from "../../shared/utilities/interfaces";
import store from "../../store/store";
import SFUItext from "../../assets/font/SFUIText.ttf";
import SFUITextBold from "../../assets/font/SFUIText-Bold.ttf";
import SFUITextLight from "../../assets/font/SFUIText-Light.ttf";
import SFUITextItalic from "../../assets/font/SFUIText-Regular.ttf";
import SFUITextBoldItalic from "../../assets/font/SFUIText-Medium.ttf";
const Theme = () => {
  const appState: IState = store.getState();
  const { themeMode } = appState.app;
  return createTheme({
    typography: {
      fontFamily: ["SF UI Text", "sans-serif"].join(","),
    },
    palette: {
      ...(themeMode === "light"
        ? {
            primary: {
              main: "#5D5D5D",
            },
            secondary: {
              main: "#F7F9FD",
              dark: "#1A1A1A",
            },
            error: {
              main: "#EB5757",
            },
            success: {
              main: "#27AE60",
              contrastText: "#219653",
            },
            warning: {
              main: "#F2994A",
            },
            hlfPalette: {
              darkGray: "#A9A9A9",
              gray1: "#828282",
              gray2: "#D3D3D3",
              gray3: "#E0E0E0",
              gray4: "#F2F2F2",
              gray5: "#E5E5E5",
              gray6: "#333333",
            },
            background: {
              paper: "#FFFFFF",
            },
          }
        : {
            primary: {
              main: "#5D5D5D",
            },
          }),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
					@font-face {
						font-family: 'SF UI Text';
						font-style: normal;
						font-weight: 400;
						font-display: swap;
						src: local('SF UI Text'), url(${SFUItext}) format('truetype');
					}
					@font-face {
						font-family: 'SF UI Text';
						font-style: normal;
						font-weight: 700;
						font-display: swap;
						src: local('SF UI Text'), url(${SFUITextBold}) format('truetype');
					}
					@font-face {
						font-family: 'SF UI Text';
						font-style: italic;
						font-weight: 400;
						font-display: swap;
						src: local('SF UI Text'), url(${SFUITextItalic}) format('truetype');
					}
					@font-face {
						font-family: 'SF UI Text';
						font-style: italic;
						font-weight: 700;
						font-display: swap;
						src: local('SF UI Text'), url(${SFUITextBoldItalic}) format('truetype');
					}
					@font-face {
						font-family: 'SF UI Text';
						font-style: normal;
						font-weight: 300;
						font-display: swap;
						src: local('SF UI Text'), url(${SFUITextLight}) format('truetype');
					}
				`,
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "#A9A9A9",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            textTransform: "none",
            fontWeight: 700,
            "&.loading": {},
          },
          text: {
            color: "black",
            ":hover": {
              boxShadow: "0px 8px 9px -5px rgba(0, 0, 0, 0.2)",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          nativeInput: {
            borderBottom: "none",
          },
          icon: {
            color: "#A9A9A9",
          },

          select: {
            color: "#1A1A1A",
            "&:focus": {
              backgroundColor: "white",
            },
            "&:error": {
              color: "red",
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: "500",
            padding: "7px 16px",
            borderRadius: "4px",
            color: "#1A1A1A",
            "&:focus": {
              backgroundColor: "white",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#A9A9A9",
            "&.Mui-focused": {
              color: "#A9A9A9",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "&.em": {
              color: "#5D5D5D",
            },
          },
          h1: {
            fontSize: "6rem",
          },
          h2: {
            fontSize: "3.75rem",
          },
          h3: {
            fontSize: "3rem",
          },
          h4: {
            fontSize: "2rem",
          },
          h5: {
            fontSize: "1.5rem",
          },
          h6: {
            fontSize: "1.25rem",
          },
          subtitle1: {
            fontSize: "1rem",
          },
          subtitle2: {
            fontSize: "0.875rem",
          },
          body1: {
            fontSize: "0.875rem",
          },
          body2: {
            fontSize: "0.75rem",
          },
        },
      },
    },
  });
};
export default Theme;
