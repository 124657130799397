import { Alert } from '@mui/material';
import { Box, styled } from '@mui/system';
import { getMobileOperatingSystem } from 'features/RFI/helpers';

export const AlertContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100vw',
  justifyContent: 'center'
}));

const MobileOs = ['iOS', 'Android'];

export const StyledAlert = styled(Alert)(({ theme, severity }) => {
  return {
    color: severity === 'error'
      ? theme.palette.error.main
      : severity === 'info'
        ? theme.palette.info.main
        : severity === 'success'
          ? theme.palette.success.main
          : severity === 'warning'
            ? theme.palette.warning.main
            : 'inherit',
    boxShadow: theme.shadows[2],
    position: 'fixed',
    marginTop: MobileOs.includes(getMobileOperatingSystem()) ? '10px' : '2rem',
    zIndex: theme.zIndex['modal'] + 1
  };
});