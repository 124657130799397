import React, { useEffect } from "react";
import { LinearProgress, ThemeProvider } from "@mui/material";
import { connect } from "react-redux";
import "./App.css";
import AppRoutes from "./routes";
import { IApp, IState } from "./shared/utilities/interfaces";
import AppTheme from "./core/theme/AppTheme";
import Header from "./core/components/header";
import Footer from "core/components/footer";
import Alert from "core/components/alert";
import { history } from "./store/store";
import AppLoader from "core/components/appLoader";
interface IRootApp {
  app: IApp;
  path: string;
}

const RootApp = (rootProps: IRootApp) => {
  const {
    app: { isLoading, isShowingModal },
    path,
  } = rootProps;
  const theme = AppTheme();
  const currentPath = history.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPath]);

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <LinearProgress />}
      {isLoading && <AppLoader />}
      {isShowingModal && <Alert />}
      {(path !== "/health-check" && !path.includes('beneficiary')) && <Header />}
      <AppRoutes />
      {(path !== "/health-check" && !path.includes('beneficiary')) && <Footer />}
    </ThemeProvider>
  );
};

export default connect((state: IState) => ({
  app: state.app,
  path: state.router.location.pathname,
  state,
}))(RootApp);
