/* eslint-disable no-unused-vars */
import { IModalData } from 'shared/utilities/interfaces';
import { AppDispatch } from '../../../store/store';

export enum CORE_ACTIONS {
  ENABLE_LOADER = 'ENABLE_LOADER',
  DISABLE_LOADER = 'DISABLE_LOADER',
  ENABLE_MODAL = 'ENABLE_MODAL',
  DISABLE_MODAL = 'DISABLE_MODAL',
  TOGGLE_APP_THEME = 'TOGGLE_APP_THEME',
  RESET_FORM = 'RESET_FORM'
}

export function enableLoader() {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
  };
}

export function toggleAppTheme(theme: string) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: CORE_ACTIONS.TOGGLE_APP_THEME,
      payload: {
        themeMode: theme
      }
    });
  };
}

export function closeModal() {
  return async function (dispatch: AppDispatch) {
    dispatch({
      type: CORE_ACTIONS.DISABLE_MODAL
    });
  };
}

export function enableModal(payload: IModalData) {
  return async function (dispatch: AppDispatch) {
    dispatch({
      type: CORE_ACTIONS.ENABLE_MODAL,
      payload: payload
    });
  };
}