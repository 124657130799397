import axios, { AxiosError, AxiosResponse } from "axios";
import { CORE_ACTIONS } from "../../core/store/app/actions";
import store, { history } from "../../store/store";
import { AccountEmail, SessionKeys } from "../constants";
import {
  getSessionValue,
  clearSessionStorage,
  setSessionItem,
  clearLocalStorage,
  getSessionStoreage,
} from "../../shared/helpers/auth.helper";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BE,
});

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((promise: any) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  failedQueue = [];
};

axiosInstance.interceptors.request.use(
  (config: any) => {
    config.baseURL = process.env.REACT_APP_BASE_URL_BE;

    if (
      getSessionValue(SessionKeys.accessToken) &&
      getSessionValue(SessionKeys.accessToken) != undefined &&
      config.url != "/api/v1/corporate/verify"
    ) {
      let bearerToken = getSessionValue(SessionKeys.accessToken);
      if (config.url.includes("/refresh")) {
        bearerToken = getSessionValue(SessionKeys.refreshToken);
      }
      config.headers = getSessionValue(SessionKeys.accessToken)
        ? {
            Authorization: `Bearer ${bearerToken}`,
          }
        : null;
    }
    if(config.data && isJson(config.data)) {
      config.data = JSON.parse(config.data);
    }
    if (!getSessionValue(AccountEmail.loader)) {
      store.dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    }
    return config;
  },
  (error: AxiosError) => {
    store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    store.dispatch({
      type: CORE_ACTIONS.ENABLE_MODAL,
      payload: error.response.data,
    });
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    return response;
  },
  (err: any) => {
    const originalRequest = err.config;

    if (err.response.status == 401 && !originalRequest._retry) {
      if (originalRequest.url.includes("/refresh")) {
        processQueue(err, null);
        store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
        return Promise.reject(err);
      }
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
            return axios(originalRequest);
          })
          .catch((isRefreshingError) => {
            store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
            return Promise.reject(isRefreshingError);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;
      const userId = getSessionStoreage(SessionKeys.userId);
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(
            `/api/v1/user/${userId}/refresh`
          )
          .then(({ data }: AxiosResponse) => {
            // set access token from response
            axiosInstance.defaults.headers.common.Authorization = `Bearer //accessToken`;
            originalRequest.headers.Authorization = `Bearer //accessToken`;
            processQueue(null, data.data.token);
            setSessionItem(SessionKeys.accessToken, data.data.token);
            setSessionItem(SessionKeys.refreshToken, data.data.refresh_token);
            store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
            resolve(axiosInstance(originalRequest));
          })
          .catch((refreshErr: Error | AxiosResponse) => {
            processQueue(refreshErr, null);
            store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
            clearSessionStorage();
            clearLocalStorage();
            store.dispatch({ type: CORE_ACTIONS.RESET_FORM });
            history.push("/login");
            reject(refreshErr);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }
    store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    return Promise.reject(err);
  }
);

function isJson(data){
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
}
  
export default axiosInstance;
