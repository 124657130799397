import { AUTH_ACTIONS } from "./actions";
import {
  IAuth,
  ReducerActionProps,
} from "../../../shared/utilities/interfaces";
import { getFromSession } from "../../../shared/helpers/auth.helper";
import { AccountEmail, SessionKeys } from "../../../core/constants";

function isUserLoggedIn() {
  const userEmail = getFromSession(SessionKeys.userEmail);
  const isLoggedIn = getFromSession(SessionKeys.isLoggedIn);
  return userEmail.success && isLoggedIn.success;
}

function getSessionValue(key: string) {
  const { result, success } = getFromSession(key);
  return success ? result : "";
}

const initialState: IAuth = {
  status: "",
  isLoggedIn: isUserLoggedIn(),
  accessToken: getSessionValue(SessionKeys.accessToken),
  refreshToken: getSessionValue(SessionKeys.refreshToken),
  userId: getSessionValue(SessionKeys.userId),
  sessionExpired: null,
  accountType: "",
  email: getSessionValue(AccountEmail.userEmail),
  userData: {
    name: "User Name",
  },
  applicationStatus: {
    complianceStatus: "",
    status: "",
  },
  countriesData: [],
  applicationDetails: {
    kycMode: "",
    firstName: "",
    middleName: "",
    lastName: "",
    nationality: "",
    dateOfBirth: "",
    isResident: "",
    professionalDetails: [
      {
        position: "",
        sharePercentage: "",
      },
    ],
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postcode: "",
    },
    contactDetails: {
      countryCode: "",
      contactNo: "",
      email: "",
    },
    documentDetails: [
      {
        documentType: "",
        documentExpiryDate: "",
        documentNumber: "",
        documentIssuanceCountry: "",
        document: [],
      },
    ],
    tags: [
      {
        key: "Referred by Hong Leong Finance (HLF)",
        value: "",
      },
      { key: "HLF Relationship Manager’s Name", value: "" },
    ],
  },

  businessDetails: {
    businessName: "",
    businessRegistrationNumber: "",
    businessType: "",
    website: "",
    legalDetails: {
      registeredCountry: "",
      registeredDate: "",
      listedExchange: "",
    },
    tradeName: "",
    trusteeName: "",
    associationDetails: {
      associationName: "",
      associationNumber: "",
      associationChairPerson: "",
    },
    regulatoryDetails: {
      regulatedTrustType: [],
    },
    addresses: {
      isSameAddress: false,
      registeredAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postcode: "",
      },
      businessAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postcode: "",
      },
    },
    documentDetails: [
      {
        documentType: "",
        document: [
          {
            fileName: "",
            fileType: "",
            document: "",
          },
        ],
      },
    ],
    riskAssessmentInfo: {
      totalEmployees: "",
      annualTurnover: "",
      industrySector: "",
      countryOfOperation: [],
      travelRestrictedCountry: "",
      restrictedCountries: [],
      ofacLicensePresent: "",
    },
  },

  stakeholders: [
    {
      stakeholderType: "Individual",
      stakeholderDetails: {
        kycMode: "MANUAL_KYC",
        firstName: "",
        middleName: "",
        lastName: "",
        nationality: "",
        dateOfBirth: "",
        isResident: "no",
        contactDetails: {
          countryCode: "",
          contactNo: "",
          email: "",
        },
        professionalDetails: [
          {
            position: "",
            sharePercentage: "",
          },
        ],
        address: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          postcode: "",
          city: "",
          state: "",
        },
        documentDetails: [
          {
            documentType: "",
            documentExpiryDate: "",
            documentNumber: "",
            documentIssuanceCountry: "",
            document: [],
          },
        ],
      },
      businessPartner: {
        businessName: "",
        businessRegistrationNumber: "",
        businessType: "",
        businessEntityType: "",
        sharePercentage: "",
        legalDetails: {
          registeredCountry: "",
        },
        addresses: {
          registeredAddress: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            country: "",
            postcode: "",
          },
          businessAddress: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            country: "",
            postcode: "",
          },
        },
      },
    },
  ],
  Stakeholder: {
    firstName: "",
    middleName: "",
    lastName: "",
    nationality: "",
    dateOfBirth: "",
    isResident: "no",
    contactDetails: {
      countryCode: "",
      contactNo: "",
      email: "",
    },
    professionalDetails: [
      {
        position: "",
        sharePercentage: "",
      },
    ],
    address: {
      addressLine1: "",
      addressLine2: "",
      country: "",
      postcode: "",
      city: "",
      state: "",
    },
    documentDetails: [
      {
        documentType: "",
        documentExpiryDate: "",
        documentNumber: "",
        documentIssuanceCountry: "",
        document: [],
      },
    ],
  },

  Businessdeatils: {
    businessName: "",
    businessRegistrationNumber: "",
    businessType: "",
    businessEntityType: "",
    sharePercentage: "",
    searchId: "",
    legalDetails: {
      registeredCountry: "",
    },
    addresses: {
      registeredAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postcode: "",
      },
      businessAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postcode: "",
      },
    },
  },
};

const AuthReducer = (state = initialState, action: ReducerActionProps) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN_REQUEST:
    case AUTH_ACTIONS.LOGIN_FAILURE: {
      return state;
    }

    case AUTH_ACTIONS.LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoggedIn: !!(action.payload.accessToken && action.payload.userId),
      };
    }

    case AUTH_ACTIONS.LOGOUT_REQUEST: {
      return {};
    }

    case AUTH_ACTIONS.SESSION_EXPIRED: {
      return {
        ...state,
        sessionExpired: true,
      };
    }

    case AUTH_ACTIONS.APPLICATION_DATA_SUCCESS: {
      return {
        ...state,
        applicationStatus: action.payload,
      };
    }

    case AUTH_ACTIONS.ADD_STAKEHOLDER_DETAILS_SUCCESS: {
      return {
        ...state,
        stakeholders: action.payload,
      };
    }
    case AUTH_ACTIONS.LOGIN_OTP_SUCCESS: {
      return {
        ...state,
        email: action.payload,
        isLoggedIn: true,
      };
    }
    case AUTH_ACTIONS.APPLICATION_DATA_FAIL: {
      return {
        ...state,
        applicationStatus: action.payload,
      };
    }
    case AUTH_ACTIONS.GET_COUNTRIES_DATA_SUCCESS:
      return {
        ...state,
        countriesData: action.payload.countries,
      };
    case AUTH_ACTIONS.ADD_USER_DETAILS_SUCCESS:
      return {
        ...state,
        applicationDetails: action.payload,
      };
    case AUTH_ACTIONS.ADD_USER_DETAILS_REQUEST:
      return {
        ...state,
      };
    case AUTH_ACTIONS.ADD_USER_DETAILS_FAILURE:
      return {};
    case AUTH_ACTIONS.ADD_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        businessDetails: action.payload,
      };
    case AUTH_ACTIONS.ADD_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        businessDetails: action.payload,
      };
    case AUTH_ACTIONS.ADD_COMPANY_DETAILS_FAILURE:
      return {};
    case AUTH_ACTIONS.ADD_IMAGE_REQUEST:
      return {
        ...state,
        fileName: action.payload.fileName,
        fileType: action.payload.fileType,
      };
    case AUTH_ACTIONS.ADD_IMAGE_FAILURE:
      return {};

    case AUTH_ACTIONS.RESET_FORM:
      return {
        ...state,
        isLoggedIn: false,
        email: "",
        applicationDetails: {
          kycMode: "",
          firstName: "",
          middleName: "",
          lastName: "",
          nationality: "",
          dateOfBirth: "",
          isResident: "",
          professionalDetails: [
            {
              position: "",
              sharePercentage: "",
            },
          ],
          address: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            country: "",
            postcode: "",
          },
          contactDetails: {
            countryCode: "",
            contactNo: "",
            email: "",
          },
          documentDetails: [
            {
              documentType: "",
              documentExpiryDate: "",
              documentNumber: "",
              documentIssuanceCountry: "",
              document: [
                {
                  fileName: "",
                  fileType: "",
                  document: "",
                },
                {
                  fileName: "",
                  fileType: "",
                  document: "",
                },
              ],
            },
          ],
          tags: [
            {
              key: "Referred by Hong Leong Finance (HLF)",
              value: "",
            },
            { key: "HLF Relationship Manager’s Name", value: "" },
          ],
        },

        businessDetails: {
          businessName: "",
          businessRegistrationNumber: "",
          businessType: "",
          website: "",
          legalDetails: {
            registeredCountry: "",
            registeredDate: "",
            listedExchange: "",
          },
          tradeName: "",
          trusteeName: "",
          associationDetails: {
            associationName: "",
            associationNumber: "",
            associationChairPerson: "",
          },
          regulatoryDetails: {
            regulatedTrustType: [],
          },
          addresses: {
            isSameAddress: false,
            registeredAddress: {
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: "",
              country: "",
              postcode: "",
            },
            businessAddress: {
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: "",
              country: "",
              postcode: "",
            },
          },
          documentDetails: [
            {
              documentType: "",
              document: [
                {
                  fileName: "",
                  fileType: "",
                  document: "",
                },
              ],
            },
          ],
          riskAssessmentInfo: {
            totalEmployees: "",
            annualTurnover: "",
            industrySector: "",
            countryOfOperation: [],
            travelRestrictedCountry: "",
            restrictedCountries: [],
            ofacLicensePresent: "",
          },
        },

        stakeholders: [
          {
            stakeholderType: "Individual",
            stakeholderDetails: {
              firstName: "",
              middleName: "",
              lastName: "",
              nationality: "",
              dateOfBirth: "",
              isResident: "no",
              contactDetails: {
                countryCode: "",
                contactNo: "",
                email: "",
              },
              professionalDetails: [
                {
                  position: "",
                  sharePercentage: "",
                },
              ],
              address: {
                addressLine1: "",
                addressLine2: "",
                country: "",
                postcode: "",
                city: "",
                state: "",
              },
              documentDetails: [],
            },
            businessPartner: {
              businessName: "",
              businessRegistrationNumber: "",
              businessType: "",
              businessEntityType: "",
              sharePercentage: "",
              searchId: "",
              legalDetails: {
                registeredCountry: "",
              },
              addresses: {
                registeredAddress: {
                  addressLine1: "",
                  addressLine2: "",
                  city: "",
                  state: "",
                  country: "",
                  postcode: "",
                },
                businessAddress: {
                  addressLine1: "",
                  addressLine2: "",
                  city: "",
                  state: "",
                  country: "",
                  postcode: "",
                },
              },
            },
          },
        ],
      };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default AuthReducer;
