export function getSessionStoreage (key: string) {
  const result = sessionStorage.getItem(key);
  try {
    return JSON.parse(result === null ? '' : result);
  } catch (e) {
    return result;
  }
}

export const undefinedArray = [null, undefined, ''];

export function getFromSession (key: string) {
  const result = sessionStorage.getItem(key);
  if (undefinedArray.indexOf(result) === -1) {
    return {
      success: true,
      result
    };
  }
  return {
    success: false,
    result
  };
}

export function setSessionItem (key: string, value: string) {
  if (key !== '' && value !== '') {
    sessionStorage.setItem(key, value);
  }
}

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const clearSessionStorageKeyName = (keyname) => {
  sessionStorage.removeItem(keyname);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};


export const getSessionValue = (key: string) => {
	const { result, success } = getFromSession(key);
	return success ? result : null;
};