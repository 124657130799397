import axiosInstance from "core/network";
import { SessionKeys } from "core/constants";
import { getSessionValue } from "shared/helpers/auth.helper";

export function getRFIDocumentsRequest() {
  return axiosInstance.get(`/api/v1/user/${getSessionValue(SessionKeys.userId)}/customer/1282adc7-8a6d-4a2f-a26f-a994d18b0bf5`);
}

export function getRFICoporateDocumentsRequest() {
  return axiosInstance.get(`/api/v1/corporate/rfi`);
}

export function updateRFIDocumentsRequest(data) {
  return axiosInstance.post(`/api/v1/user/${getSessionValue(SessionKeys.userId)}/rfi`, data);
}

export function updateRFICorporateDocumentsRequest(data) {
  return axiosInstance.post(`/api/v1/corporate/rfi`, data);
}