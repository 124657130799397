/* eslint-disable no-unused-vars */
export enum SessionKeys {
  accessToken = "accessToken",
  userId = "userId",
  refreshToken = "refreshToken",
  accountStatus = "accountStatus",
  userEmail = "userEmail",
  loader = "loader",
  isLoggedIn = "isLoggedIn",
  customerType = "customerType"
}

export enum AuthRoles {
  CORPORATE = "corporate",
  CONSUMER = "individual"
}

export enum AccountTypes {
  BRAND = "BRAND",
  CREATOR = "CREATOR",
}

export enum AccountEmail {
  userEmail = "userEmail",
  loader = "loader",
  isLoggedIn = "isLoggedIn",
}

export enum BusinessValues {
  businessSameAddress = "sameAddress",
  businessTravelRestrict = "travelRestrict",
  businessOfacLicense = "ofacLicense",
}
