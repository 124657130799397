/* eslint-disable no-console */
import { CHANGE_RFI_STEP, RFI_ACTIONS } from './actions';
import {
  ReducerActionProps
} from '../../../shared/utilities/interfaces';
import { RFI_STEPS_TYPE } from '../helpers/constants';

const initialState = {
  rfiDocuments: {},
  currentStep: 1,
  lastRfiHashId: ''
};

const RfiReducer = (state = initialState, action: ReducerActionProps) => {
  switch (action.type) {
    case RFI_ACTIONS.GET_RFI_DOCUMENTS_FAILURE: {
      return state;
    }

    case RFI_ACTIONS.GET_RFI_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        rfiDocuments: action.payload,
        lastRfiHashId: action.payload?.rfiDetails?.
        [action.payload?.rfiDetails?.length - 1]?.rfiHashId ||
          action.payload?.rfiTemplates?.
          [action.payload?.rfiTemplates?.length - 1]?.templateId,
        rfiLength: action.payload?.rfiDetails?.length ||
          action.payload?.rfiTemplates?.length 
      };
    }
    case RFI_ACTIONS.UPDATE_RFI_DOCUMENTS_SUCCESS:{
      return {
        ...state,
        rfiSubmitResponse: action.payload,
      };
    }
    case RFI_ACTIONS.UPDATE_RFI_DOCUMENTS_FAILURE:{
      return {
        ...state,
        rfiSubmitResponse: action.payload,
      };}
      
    case CHANGE_RFI_STEP: {
      let step = 0;
      if (action.payload === RFI_STEPS_TYPE.INCREMENT) {
        step = 1;
      } else if (action.payload === RFI_STEPS_TYPE.DECREMENT) {
        step = (-1);
      }
      return {
        ...state,
        currentStep: state.currentStep + step,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default RfiReducer;