import { lazy } from "react";

const routes = [
  {
    path: "/",
    exact: false,
    isPrivate: false,
    component: lazy(() => import("../features/auth/container/container")),
  },
  {
    path: "/onboarding",
    exact: false,
    isPrivate: true,
    component: lazy(() => import("../features/auth/container")),
  },
  {
    path: "/rfi",
    exact: false,
    isPrivate: false,
    component: lazy(() => import("../features/RFI/container/index")),
  },
  {
    path: "/health-check",
    exact: false,
    isPrivate: false,
    component: lazy(() => import("../core/components/health-check")),
  },
  {
    path: "/beneficiary",
    exact: false,
    isPrivate: false,
    component: lazy(() => import("../features/beneficiary/container/index")),
  },
];

export default routes;
