import { Box, Typography } from "@mui/material";
import { SessionKeys } from "core/constants";
import React from "react";
import { getSessionValue } from "shared/helpers/auth.helper";


function Footer() {
  if (getSessionValue(SessionKeys.customerType) === "individual") {
    return (
      <footer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid #D3D3D3",
            margin: "0 24px",
            height: "48px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              p: "0.75rem",
              color: "secondary.dark",
              fontWeight: "bold",
              display: "flex",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "secondary.dark",
                fontWeight: "400",
                paddingRight: "5px",
              }}
            >
              © 2022
            </Typography>
            NIUM PTE LTD
          </Typography>
        </Box>
      </footer>
    );
  } else {
    return (
      <footer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid #D3D3D3",
            margin: "0 24px",
            height: "48px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              p: "0.75rem",
              color: "secondary.dark",
              fontWeight: "bold",
              display: "flex",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "secondary.dark",
                fontWeight: "400",
                paddingRight: "5px",
              }}
            >
              © 2022
            </Typography>
            NIUM PTE LTD
            <Typography
              variant="body2"
              sx={{
                color: "secondary.dark",
                fontWeight: "900",
                ml: "7px",
              }}
            >
              |
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "secondary.dark",
                fontWeight: "400",
                paddingRight: "5px",
                ml: "7px",
              }}
            >
              fastpay-support@nium.com
            </Typography>
          </Typography>
        </Box>
      </footer>
    );
  }
    
}

export default Footer;
