import axiosInstance from "../../../core/network";
import { LoginFormBody, LoginOtpFormBody } from "../utilites";
import { SessionKeys } from '../../../core/constants';
import { setSessionItem } from "../../../shared/helpers/auth.helper";

export function loginOtpRequest(body: LoginOtpFormBody) {
  return axiosInstance.post("/api/v1/corporate/verify", body);
}
export function loginRequest(body: LoginFormBody) {
  return axiosInstance.post("/api/v1/corporate/verify", body);
}
export function fetchUser(userId, customerHashId, token) {
  setSessionItem(SessionKeys.accessToken, token);
  return axiosInstance.get(
    `/api/v1/user/${userId}/customer/${customerHashId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
}

export function getCountries() {
  return axiosInstance.get("/api/v1/public/countries");
}
export function stakeHolderRequest(body) {
  return axiosInstance.post("/api/v1/corporate", body);
}

export function getTermAndCondition() {
  return axiosInstance.get(`/api/v1/user/terms-condition/v2?role=CORPORATE`);
}
