/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
import { Dispatch } from "redux";
import { history } from "store/store";
import { UpdateRFIDocAPIProps } from "../container/rfiForms";
import {
  getRFIDocumentsRequest,
  updateRFIDocumentsRequest,
  getRFICoporateDocumentsRequest,
  updateRFICorporateDocumentsRequest,
} from "./api";
import { CORE_ACTIONS } from "core/store/app/actions";
import { getAlertType } from "shared/helpers";
import moment from "moment";
import { setSessionItem } from "shared/helpers/auth.helper";
import { SessionKeys } from "core/constants";

/* eslint-disable no-unused-vars */
export enum RFI_ACTIONS {
  GET_RFI_DOCUMENTS_SUCCESS = "GET_RFI_DOCUMENTS_SUCCESS",
  GET_RFI_DOCUMENTS_FAILURE = "GET_RFI_DOCUMENTS_FAILURE",
  UPDATE_RFI_DOCUMENTS_REQUEST = "UPDATE_RFI_DOCUMENTS_REQUEST",
  UPDATE_RFI_DOCUMENTS_SUCCESS = "UPDATE_RFI_DOCUMENTS_SUCCESS",
  UPDATE_RFI_DOCUMENTS_FAILURE = "UPDATE_RFI_DOCUMENTS_FAILURE",
  RFI_SUBMIT_RESPONSE_FAILURE = "RFI_SUBMIT_RESPONSE_FAILURE",
  RFI_SUBMIT_RESPONSE_SUCCESS = "RFI_SUBMIT_RESPONSE_SUCCESS",
}

export function getRFIDocuments() {
  return async (dispatch: Dispatch) => {
    try {
      // API Call
      const response = await getRFIDocumentsRequest();
      setSessionItem(SessionKeys.customerType, response.data.data.customerType.toLowerCase());
      if(response?.data?.data?.rfiDetails?.length) {
        response.data.data.rfiDetails = response.data.data.rfiDetails.filter((el) => el.rfiType !== "INTERNAL");
      }
      dispatch({
        type: RFI_ACTIONS.GET_RFI_DOCUMENTS_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: error.response.data.message,
          severity: getAlertType(400),
          alertTitle: error.response.data.error,
        },
      });
    }
  };
}

export function getRFICoporateDocuments() {
  return async (dispatch: Dispatch) => {
    try {
      // API Call
      const response = await getRFICoporateDocumentsRequest();
      dispatch({
        type: RFI_ACTIONS.GET_RFI_DOCUMENTS_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: error.response.data.message,
          severity: getAlertType(400),
          alertTitle: error.response.data.error,
        },
      });
    }
  };
}

export const CHANGE_RFI_STEP = "CHANGE_RFI_STEP";

export function changeRFISteps(stepType) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CHANGE_RFI_STEP,
      payload: stepType,
    });
  };
}

export function updateRFIDocuments(
  payload: UpdateRFIDocAPIProps,
  userType: string
) {
  return async (dispatch, getState) => {
    const { lastRfiHashId, rfiLength } = getState().rfi;

    try {
      const response = payload;
      if (
        response?.data?.[response.data.currentStep]?.businessInfo
          ?.documentDetails?.document?.length
      ) {
        response.data[
          response.data.currentStep
        ].businessInfo.documentDetails.document = response.data[
          response.data.currentStep
        ].businessInfo.documentDetails.document.filter((doc) => doc);
      }
      dispatch({
        type: RFI_ACTIONS.UPDATE_RFI_DOCUMENTS_SUCCESS,
        payload: response.data,
      });
      if (userType === "Corporate") {
        const payloadData = [];
        Object.keys(payload?.data).map((data) => {
          if (data !== "currentStep") payloadData.push(data);
        });

        if (payload?.data?.currentStep === rfiLength) {
          const sendData = payloadData.map((data) => {
            return payload?.data?.[data];
          });
          dispatch(
            submitRFIDocuments({ rfiResponseRequest: sendData }, userType)
          );
        }
      } else if (userType === "Consumer") {
        if (payload?.data?.[lastRfiHashId]) {
          const sendData = Object.keys(payload?.data).map((data) => {
            return payload?.data?.[data];
          });
          dispatch(
            submitRFIDocuments({ rfiResponseRequest: sendData }, userType)
          );
        }
      }
    } catch (error) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: error.response.data.message,
          severity: getAlertType(400),
          alertTitle: error.response.data.error,
        },
      });
    }
  };
}

export function submitRFIDocuments(payload: any, userType) {
  return async (dispatch) => {
    try {
      const copyObj = JSON.parse(JSON.stringify(payload));
      copyObj.rfiResponseRequest.map((item) => {
        if (userType === "Consumer") {
          if (item.identificationDoc) {
            for (const property in item.identificationDoc) {
              const newKey = property.split("_")[0];
              if (property.split("_").length > 1) {
                item.identificationDoc[newKey] =
                  item.identificationDoc[property];
                delete item.identificationDoc[property];
              } else {
                item.identificationDoc[newKey] =
                  item.identificationDoc[property];
              }
            }
          } else {
            for (const property in item) {
              const newKey = property.split("_")[0];
              if (property.split("_").length > 1) {
                item[newKey] = item[property];
                delete item[property];
              } else {
                item[newKey] = item[property];
              }
            }
          }
        }
      });
      copyObj.rfiResponseRequest.map((item) => {
        if (item["dateOfBirth"] && item["dateOfBirth"] !== "") {
          const appDateofBirth = item["dateOfBirth"];
          item["dateOfBirth"] = moment(appDateofBirth).format("YYYY-MM-DD");
        }
      });
      const response =
        userType === "Corporate"
          ? await updateRFICorporateDocumentsRequest(copyObj)
          : await updateRFIDocumentsRequest(copyObj);

      const { data } = response;
      await dispatch({
        type: RFI_ACTIONS.UPDATE_RFI_DOCUMENTS_SUCCESS,
        payload: response.data,
      });
      if (
        data.success &&
        data.data.redirectUrl === "" &&
        userType === "Consumer"
      ) {
        history.push("/rfi");
      } else {
        history.push("/rfi/rfi-corporate-success");
      }
      if (
        data.success &&
        data.data.redirectUrl !== "" &&
        userType === "Consumer"
      ) {
        // window.location.href = data.data.redirectUrl;
        history.push("/rfi");
      } else {
        history.push("/rfi/rfi-corporate-success");
      }
      dispatch({
        type: RFI_ACTIONS.RFI_SUBMIT_RESPONSE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: error.response.data.message,
          severity: getAlertType(400),
          alertTitle: error.response.data.error,
        },
      });
    }
  };
}
