/* eslint-disable no-console */
import moment from "moment";
import * as Yup from "yup";
import { FieldsJsonType } from "../../../shared/utilities/interfaces";

export interface FormFieldType {
  name?: string;
  label?: string;
  field?: string;
  url?: string;
  validations?: FieldsJsonType["validations"];
  disabled?: boolean;
  showCaptureButton?: boolean;
  showUploadButton?: boolean;
}

export default function (document: FieldsJsonType, rfiData) {
  if (document) {
    const response: any = {
      initialValues: {},
      formField: [],
      type: "",
      title: "",
    };
    const validationFields = {
      image: undefined,
    };

    const getIndex = (label) => {
      return label.split(" ").join("_");
    };

    const getMax = (label) => {
      switch (label) {
        case "First_Name":
        case "Middle_Name":
        case "Last_Name":
        case "Address_Line1":
        case "Address_Line2":
        case "Billing_Landmark":
          return 40;
        case "Nationality":
          return 2;
        case "City":
          return 20;
        case "State":
          return 30;
        case "Post_Code":
          return 10;
        default:
          return 10;
      }
    };

    if (document?.requiredData && document?.requiredData.length > 0) {
      document?.requiredData.forEach((rfi: { label; type; value }, index) => {
        response.initialValues[`${rfi?.value}_${document?.rfiHashId}`] =
          document?.type === "document"
            ? rfiData?.[document?.rfiHashId]?.identificationDoc?.[`${rfi?.value}_${document?.rfiHashId}`]
            : rfiData?.[document?.rfiHashId]?.[`${rfi?.value}_${document?.rfiHashId}`] || "";

        response.initialValues.rfiHashId = document?.rfiHashId;
        response.title = document.description;

        response.formField.push({
          name: `${rfi?.value}_${document?.rfiHashId}`,
          label: rfi.label,
          field: rfi.type,
          extraFields: document.requiredData,
        });
        response.type = document.type;
        response.documentType = document.documentType;
        if (document.requiredData) {
          // code not reqd //
          if (rfi.type === "data") {
            if (getIndex(rfi.label).includes("Date")) {
              validationFields[`${rfi?.value}_${document?.rfiHashId}`] =
                document.mandatory === true && rfi.type === "data"
                  ? rfi.value === "dateOfBirth"
                    ? Yup.string()
                      .required("This field is required")
                      .test(
                        "DOB",
                        "The minimum user age should be 18 years",
                        (value) => {
                          return moment().diff(moment(value), "years") >= 18;
                        }
                      )
                      .test("DOB", "Date should be in DD-MM-YYYY format", (value) => {
                        return value?.split(" ")[3] >= "1000";
                      })
                      .nullable()
                    : rfi.value === "identificationIssuingDate"
                      ? Yup.string()
                        .nullable()
                        .required("This field is required")
                        .test(
                          "Document",
                          "Date should be from past",
                          (value) => {
                            return moment().diff(moment(value), "day") >= 0;
                          }
                        )
                        .test("IssuingDate", "Date should be in DD-MM-YYYY format", (value) => {
                          return value?.split(" ")[3] >= "1000";
                        })
                      : rfi.value === "identificationDocExpiry"
                        ? Yup.string()
                          .nullable()
                          .required("This field is required")
                          .test(
                            "Document",
                            "Date should be in future",
                            (value) => {
                              return moment().diff(moment(value), "day") <= 0;
                            }
                          )
                          .test("DocExpiry", "Date should be in DD-MM-YYYY format", (value) => {
                            return value?.split(" ")[3] >= "1000";
                          })
                        : Yup.string()
                          .required(`This field is Required.`)
                          .nullable()
                  : Yup.string().nullable();
            } else {
              const maxVal = getMax(getIndex(rfi.label));
              validationFields[`${rfi?.value}_${document?.rfiHashId}`] =
                document.mandatory === true && rfi.type === "data"
                  ? rfi.value === "firstName" ||
                    rfi.value === "lastName" ||
                    rfi.value === "city" ||
                    rfi.value === "state"
                    ? Yup.string()
                      .trim()
                      .test(
                        "alphabets",
                        "Only alphabets are allowed for this field",
                        (value) => {
                          return /^[A-Za-z\s]+$/.test(value);
                        }
                      )
                      .required(`This field is Required.`)
                      .max(maxVal, `Maximum character limit: ${maxVal}`)
                      .nullable()
                    : rfi.value === "addressLine1" ||
                      rfi.value === "addressLine2"
                      ? Yup.string()
                        .trim()
                        .required(`This field is Required.`)
                        .max(maxVal, `Maximum character limit: ${maxVal}`)
                        .nullable()
                      : rfi.value === "postcode"
                        ? Yup.string()
                          .trim()
                          .required("This field is required")
                          .matches(/^[0-9]+$/, "Please enter only number")
                          .min(3, "Minimum character limit: 3")
                          .max(10, "Maximum character limit: 10")
                          .nullable()
                        : rfi.value === "identificationIssuingDate"
                          ? Yup.string()
                            .nullable()
                            .required("This field is required")
                            .test("Document", "Date should be in past", (value) => {
                              return moment().diff(moment(value), "day") >= 1;
                            })
                          : Yup.string()
                            .trim()
                            .required(`This field is Required.`)
                            .nullable()
                  : Yup.string()
                    .trim()
                    .required(`This field is Required.`)
                    .nullable();
            }
          }
        }
      });
      return {
        document,
        ...response,
        validations: Yup.object().shape(validationFields),
      };
    }
  }
  return {};
}

export function getMobileOperatingSystem() {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function camelCaseToNormal(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
