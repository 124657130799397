import { connectRouter } from "connected-react-router";
import { AnyAction, combineReducers } from "redux";
import { IState } from "../shared/utilities/interfaces";
import Auth from "../features/auth/store/reducers";
import RFI from "../features/RFI/store/reducers";
import Core from "../core/store/app/reducers";
// HYGEN-IMPORT

const combinedReducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth: Auth,
    app: Core,
    rfi: RFI,
  }); // HYGEN-ADD-REDUCER

const createRootReducer =
  (history: any) => (state: IState, action: AnyAction) => {
    return combinedReducers(history)(state, action);
  };

export default createRootReducer;
