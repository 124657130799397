/* eslint-disable no-unused-vars */
import React, { Suspense } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import AllRoutes from "./routes";
import store from "../store/store";
import { IState } from "../shared/utilities/interfaces";
import AppLoader from "core/components/appLoader";

const Routes = () => {
  const {
    auth: { isLoggedIn },
  }: IState = store.getState();

  let AppRoutes;
  if (isLoggedIn) {
    AppRoutes = AllRoutes.filter((item) => {
      if (item.isPrivate) {
        return item;
      }
    });
  } else {
    AppRoutes = AllRoutes.filter((item) => {
      if (!item.isPrivate) {
        return item;
      }
    });
  }
  return (
    <>
      {AllRoutes.map((singleRoute) => (
        <Route
          component={singleRoute.component}
          exact={singleRoute.exact}
          path={singleRoute.path}
          key={singleRoute.path}
        />
      ))}
    </>
  );
};

const MainRoutes = () => {
  return (
    <Suspense fallback={<AppLoader />}>
      <Switch>
        <Routes />
      </Switch>
    </Suspense>
  );
};

export default MainRoutes;
